<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="tbmData.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-process
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processCd"
                  v-model="tbmData.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-vendor
                  :editable="editable"
                  :disabled="disabled"
                  :haveProcess="true"
                  label="업체"
                  name="vendorCd"
                  v-model="tbmData.vendorCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-work-permit
                  :editable="editable"
                  :disabled="disabled"
                  :isRemoveConfirm="true"
                  label="작업허가번호 | 작업일"
                  name="sopWorkPermitId"
                  v-model="tbmData.sopWorkPermitId"
                  @input="input"
                  @remove="removeWorkPermit"
                >
                </c-work-permit> 
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="TBM번호"
                  name="tbmNo"
                  v-model="tbmData.tbmNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM명"
                  name="tbmName"
                  v-model="tbmData.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM일자"
                  type="date"
                  name="tbmDate"
                  v-model="tbmData.tbmDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :range="true"
                  :minuteStep="10"
                  type="time"
                  label="TBM시간"
                  name="workTime"
                  v-model="workTime"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM장소"
                  name="tbmLocation"
                  v-model="tbmData.tbmLocation"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="작업"
                  name="tbmName"
                  v-model="tbmData.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  type="dept_job_user"
                  label="TBM리더"
                  name="tbmLeaderId"
                  v-model="tbmData.tbmLeaderId"
                />  
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  name="tbmContents"
                  v-model="tbmData.tbmContents"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업 전 일일 안전점검 시행 결과"
                  name="preInspectionResult"
                  v-model="tbmData.preInspectionResult"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업 후 종료 미팅 (중점대책의 실효성)"
                  name="tbmResult"
                  v-model="tbmData.tbmResult"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="riskGrid.columns"
            :data="tbmData.tbmRiskList"
            :isTitle="true"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="위험요인을 추가하세요."
            :hideBottom="false"
            :editable="editable && !disabled"
            rowKey="tbmRiskId"
            selection="multiple"
            :isFullScreen="false"
            :isExcelDown="false"
            :gridHeightAuto="true"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="3단계판단법"
                  icon="add"
                  @btnClicked="add3rdRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="4M"
                  icon="add"
                  @btnClicked="add4MRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="JRA"
                  icon="add"
                  @btnClicked="addJRARisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="직접"
                  icon="add"
                  @btnClicked="addRisk"
                />
                <c-btn
                  v-if="editable && !disabled && tbmData.tbmRiskList.length > 0"
                  :showLoading="false"
                  label=""
                  icon="remove"
                  @btnClicked="removeRisk"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmRisk'">
                <c-text-column
                  v-if="props.row['tbmRiskType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmRisk']"
                  @datachange1="datachange1(props)"
                />
                <span v-else>
                  {{props.row['tbmRisk']}}
                </span>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="attendeeTable"
            title="참석자 목록"
            :columns="attendeeGrid.columns"
            :data="tbmData.tbmAttendeeList"
            :isTitle="true"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :gridHeightAuto="true"
            :hideBottom="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="tbmAttendeeId"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="협력"
                  icon="add"
                  @btnClicked="addAttendeePartner"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="현업"
                  icon="add"
                  @btnClicked="addAttendee"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="직접"
                  icon="add"
                  @btnClicked="addAttendeeDirect"
                /> 
                <c-btn
                  v-if="editable && !disabled && tbmData.tbmAttendeeList.length > 0"
                  :showLoading="false"
                  label=""
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmAttendeeDept'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeDept']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeDept']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeJob'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeJob']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeJob']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeName'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeName']}}
                </span>
              </template>
              <!-- 참석자 직접추가가 아닌 경우 -->
              <template v-if="col.name === 'click'">
                <q-btn 
                  v-if="props.row['attendeeType'] !== '직접추가'"
                  round unelevated 
                  size="xs"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { rowIndex = props.rowIndex, attendeeSign = props.row.attendeeSign}"
                  >
                  <q-popup-proxy :ref="'proxy_' + props.rowIndex" :breakpoint="400">
                    <component
                      :is="col.component"
                      :outFlag="true"
                      :popupParam="props.row"
                      :data="tbmData"
                      :rowIndex="props.rowIndex"
                      @attendeeSignCallback="attendeeSignCallback"
                    />
                  </q-popup-proxy>
                </q-btn>
              </template>

              <template v-if="col.name==='customCol'">
                <component
                  :is="detailComponent"
                  :col="col"
                  :props="props"
                  :rowIndex="props.rowIndex"
                  tableKey="tbmAttendeeId"
                  @closeAttendeeDetail="closeAttendeeDetail"
                >
                <template v-slot:customArea="{ props }">
                  <q-form ref="editForm2">
                    <c-card title="참석자 세부 정보" class="cardClassDetailForm q-mb-sm">
                      <!-- <template slot="card-button">
                        <q-btn-group outline >
                          <c-btn
                            :showLoading="false"
                            label="닫기"
                            icon="close"
                            @btnClicked="dialogClose"
                          />
                          <c-btn
                            :showLoading="false"
                            label="적용"
                            icon="check"
                            color="amber"
                            @btnClicked="dialogSubmit"
                          />
                        </q-btn-group>
                      </template> -->
                      <template slot="card-detail">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <c-text
                            :disabled="popupParam.disabled||props.row['attendeeType'] !== '직접추가'"
                            :editable="editable"
                            label="참석자"
                            name="tbmAttendeeName"
                            v-model="props.row.tbmAttendeeName">
                          </c-text>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <c-text
                            :disabled="popupParam.disabled||props.row['attendeeType'] !== '직접추가'"
                            :editable="editable"
                            label="직책"
                            name="tbmAttendeeJob"
                            v-model="props.row.tbmAttendeeJob">
                          </c-text>
                        </div>
                        <div class="col-12">
                          <c-textarea
                            :disabled="popupParam.disabled"
                            :editable="editable"
                            :rows="2"
                            label="참석자 비고"
                            name="remark"
                            v-model="props.row.remark">
                          </c-textarea>
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                          <c-checkbox
                            :disabled="popupParam.disabled"
                            :isFlag="true"
                            label="정신적피로"
                            name="mentalFlag"
                            v-model="props.row.mentalFlag"
                          />
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                          <c-checkbox
                            :disabled="popupParam.disabled"
                            :isFlag="true"
                            label="심리적피로"
                            name="phychologicalFlag"
                            v-model="props.row.phychologicalFlag"
                          />
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                          <c-checkbox
                            :disabled="popupParam.disabled"
                            :isFlag="true"
                            label="육체적피로"
                            name="physicalFlag"
                            v-model="props.row.physicalFlag"
                          />
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                          <c-checkbox
                            :disabled="popupParam.disabled"
                            :isFlag="true"
                            label="신체적결함"
                            name="physicalDefectFlag"
                            v-model="props.row.physicalDefectFlag"
                          />
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <c-select
                            :disabled="popupParam.disabled"
                            codeGroupCd="TBM_HEALTH_STATUS_CD"
                            type="search"
                            itemText="codeName"
                            itemValue="code"
                            name="healthStatusCd"
                            label="건강상태"
                            v-model="props.row.healthStatusCd"
                          ></c-select>
                        </div>
                      </template>
                    </c-card>
                  </q-form>
                </template>
                </component>
              </template> 
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="교육자료">
          </c-upload>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-show="editable && !popupParam.tbmId"
            label="불러오기"
            icon="save_alt"
            @btnClicked="copyTbm"
          />
          <c-btn
            :flat="true"
            v-show="editable && popupParam.tbmId && !disabled"
            label="삭제"
            :editable="editable"
            icon="delete_forever"
            @btnClicked="remove"
          />
          <c-btn
            :flat="true"
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="tbmData"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveTbm"
            @btnCallback="saveCallback"
          />
          <!-- [S]결재관련 버튼 -->
          <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
          <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
          <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
          <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
          <!-- <c-appr-btn 
            :flat="true"
            ref="appr-btn"
            name="danger-journal-appr-btn"
            :editable="editable"
            :approvalInfo="approvalInfo"
            @beforeApprAction="saveDataAppr"
            @callbackApprAction="approvalCallback"
            @requestAfterAction="getDetail"
          />
          <c-btn
            :flat="true"
            v-show="editable && popupParam.tbmId && !disabled"
            :url="completeUrl"
            :isSubmit="isComplete"
            :param="data"
            mappingType="PUT"
            label="완료"
            icon="check"
            @beforeAction="completeTbm"
            @btnCallback="completeCallback"
          /> -->
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import { uid } from "quasar";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
// 스크롤시 저장버튼(최초)
import mixinCommon from '@/js/mixin-page-common'
export default {
  mixins: [mixinCommon],
  name: "tbm-info",
  components: {
    VueQrcode
  },
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
          disabled: false,
        };
      },
    },
    // 스크롤시 저장버튼(최초)
    eventScroll: {
      type: Object,
      default(){
        return {
          position: null,
          direction: null,
          save: null,
          isSave: false,
        }
      },
    },
  },
  data() {
    return {
      tbmData: {
        tbmId: "", 
        tbmNo: "", 
        plantCd: "", // 사업장코드
        permitDate: "", // 참조 작업허가서 날짜
        workName: "", // 작업명
        tbmLeaderDept: '', // TBM리더 부서or(외부업체인 경우)회사
        tbmLeaderId: '', // TBM리더 이름
        tbmLeaderSign: '', // TBM리더 전자서명
        tbmName: "", // TBM 타이틀
        tbmDate: "", // TBM 진행 날짜
        tbmStarTime: '', // 시작시간
        tbmEndTime: '', // 종료시간
        tbmLocation: "", // 장소
        tbmContents: "", // 작업내용
        preInspectionResult: '', // 사전점검결과
        tbmResult: '', // TBM 결과 (중점대책의 실효성)
        tbmCompleteFlag: 'N', // TBM 진행단계
        processCd: "", // 공정코드
        vendorCd: "", // 협력사 코드
        sopWorkPermitId: "", // 참조 작업허가서 번호
        permitNo: '',

        regUserId: '',  // 작성자 ID
        regUserName: '', 
        regUserDeptName: '',
        regDtStringTime: '',
        regDt: '',
        chgUserId: '',
        chgUserName: '',
        chgUserDeptName: '',
        chgDtStringTime: '',
        chgDt: '',
        tbmAttendeeList: [],
        tbmRiskList: [],
        deleteTbmAttendeeList: [],
        deleteTbmRiskList: [],
      },
      workTime: [], // 작업시간 (리스트타입) [시작시간 ~ 종료시간]
      riskGrid: {
        columns: [
          // {
          //   name: "tbmJobStep",
          //   field: "tbmJobStep",
          //   label: "작업단계",
          //   align: "left",
          //   style: "width:40%",
          //   type: "text",
          //   sortable: false,
          //   colClass: 6,
          // },
          {
            required: true,
            name: "tbmRisk",
            field: "tbmRisk",
            label: "위험요인",
            align: "left",
            style: "width:40%",
            type: "custom",
            sortable: false,
          },
          {
            name: "tbmImprove",
            field: "tbmImprove",
            label: "안전대책",
            style: "width:50%",
            type: "text",
            align: "left",
            sortable: false,
          },
          {
            name: "tbmRiskKeyFlag",
            field: "tbmRiskKeyFlag",
            label: "중점",
            type: "check",
            true: 'Y',
            false: 'N',
            style: "width:10%",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      attendeeGrid: {
        columns: [
          {
            required: true,
            name: "tbmAttendeeDept",
            field: "tbmAttendeeDept",
            label: "소속",
            style: 'width:30%',
            align: "center",
            sortable: false,
            type: 'custom'
          },
          {
            required: true,
            name: "tbmAttendeeName",
            field: "tbmAttendeeName",
            label: "참석자",
            style: 'width:30%',
            align: "center",
            sortable: false,
            type: 'custom'
          },
          {
            name: 'click',
            field: 'click',
            label: '서명<br>',
            // style: 'width:45px',
            type: 'custom',
            align: 'center',
            sortable: false,
            visible: true,
            component: () => import(`${'./signaturePopAttendee.vue'}`),
          },
          // {
          //   name: 'healthStatusCd',
          //   field: 'healthStatusCd',
          //   label: '건강',
          //   type: 'select',
          //   // style: 'width:90px',
          //   align: 'center',
          //   codeGroupCd: 'TBM_HEALTH_STATUS_CD',
          //   sortable: false,
          // },
          // {
          //   name: 'mentalFlag',
          //   field: 'mentalFlag',
          //   label: '정신<br>피로',
          //   // style: 'width:45px', 
          //   align: 'center',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          // {
          //   name: 'phychologicalFlag',
          //   field: 'phychologicalFlag',
          //   label: '심리<br>피로',
          //   // style: 'width:45px', 
          //   align: 'center',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          // {
          //   name: 'physicalFlag',
          //   field: 'physicalFlag',
          //   label: '육체<br>피로',
          //   // style: 'width:45px', 
          //   align: 'center',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          // {
          //   name: 'physicalDefectFlag',
          //   field: 'physicalDefectFlag',
          //   label: '신체<br>결함',
          //   // style: 'width:45px', 
          //   align: 'center',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          // {
          //   name: "tbmAttendeeJob",
          //   field: "tbmAttendeeJob",
          //   label: "직책",
          //   // style: 'width:80px',
          //   align: "center",
          //   sortable: false,
          //   type: 'custom'
          // },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   type: 'textarea',
          //   // style: 'width:120px',
          //   align: 'left',
          //   sortable: false,
          // },
          {
            name: 'customCol',
            field: 'customCol',
            label: '+',
            align: 'center',
            // style: 'width:25px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      getUrl: "",
      saveUrl: '',
      insertUrl: "",
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveCall: {
        saveCallData: '',
      },
      editable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'TBM_EDUCATION',
        taskKey: '',
        beforeTaskKey: '',
      },
      isAttendeeVisible: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    disabled() { return this.tbmData.tbmCompleteFlag === "Y" },
    sameDate() { return this.tbmData.permitDate === this.tbmData.tbmDate },
    // 참석자 상세정보 Component
    detailComponent() { return () => import(`${'./mobileDetail.vue'}`);},
  },
  // 스크롤시 저장버튼(최초)
  watch: {
    'eventScroll.save'() {
      this.saveTbm();
    },
  },
  // * 작업허가서 있을때
  // - 프로젝트, 공종, 업체, 작업, 작업책임자(업체감독자), 작업장소, 작업일, 작업내용 전부 disabled
  // - 참석자 : 작업허가서의 작업자
  // * 작업허가서 없을때
  // - 공사현장과 관련된 공종 선택
  // - 작업장소 : 공사현장 > 작업장소
  // - 작업책임자 : 하도급 > 안전책임자
  // - 작업 : 공종, 업체에 맞는 작업 선택
  // - 업체 : 프로젝트와 공종에 맞는 하도급 선택
  // - 위험요인 목록  작업과 관련된 작업단계(직접추가 제외)
  // - 참석자 : 직영일때 사용자 추가(이름, 직책/직무 수정X), 업체일때 행추가(이름, 직책/직무 수정O) 
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.getUrl = selectConfig.sai.tbm.get.url;
      this.saveUrl = transactionConfig.sai.tbm.insert.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.printUrl = selectConfig.sai.tbm.print.url;
      this.eventScroll.position = 50
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData = this.$_.clone(_result.data);
          this.tbmData.permitNo = _result.data.permitNo + " | " + _result.data.permitDate

          this.$_.forEach(this.tbmData.tbmAttendeeList, _item => {
            _item.headerName = _item.tbmAttendeeName
          })
          this.$set(this.attachInfo, 'taskKey', this.popupParam.tbmId);
          this.workTime = [_result.data.tbmStartTime, _result.data.tbmEndTime]
        },);
      } else {
        this.tbmData.tbmDate = this.$comm.getToday()
        this.workTime = ['08:00', '09:00']
      }
    },
    saveTbm() {
      if (this.popupParam.tbmId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
      }
      this.tbmData.tbmStartTime = this.workTime[0]
      this.tbmData.tbmEndTime = this.workTime[1]
      this.$refs["editForm"].validate().then((_result) => {
        if (_result && this.$comm.validTable(this.riskGrid.columns, this.tbmData.tbmRiskList) 
        && this.$comm.validTable(this.attendeeGrid.columns, this.tbmData.tbmAttendeeList)) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.tbmData.tbmId){
                this.tbmData.chgUserId = this.$store.getters.user.userId;
              } else {
                this.tbmData.regUserId = this.$store.getters.user.userId
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      this.popupParam.tbmId = _result.data
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addRisk() {
      this.tbmData.tbmRiskList.splice(0, 0, {
        tbmId: this.tbmData.tbmId,
        tbmRiskId: uid(),
        tbmRiskProcess: "",
        tbmRisk: "",
        tbmImprove: "",
        tbmRiskKeyFlag: 'N',
        tbmRiskType: '직접추가',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmRiskList) {this.tbmData.deleteTbmRiskList = [];}
          if (this.$_.findIndex(this.tbmData.deleteTbmRiskList, {
              tbmRiskId: item.tbmRiskId}) === -1 && item.editFlag !== "C"
          ) {this.tbmData.deleteTbmRiskList.push(item);}
          this.tbmData.tbmRiskList = this.$_.reject(this.tbmData.tbmRiskList, item);
        });
      }
    },
    add3rdRisk() {
      this.popupOptions.title = "3단계 판단법 검색"; 
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/3rdRamListPop.vue'}`);
      this.popupOptions.width = "100%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.close3rdPopup
    },
    add4MRisk() {
      this.popupOptions.title = '4M';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/fmAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.close4MPopPopup;
    },
    addJRARisk() {
      this.popupOptions.title = 'JRA';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/jraAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeJRAPopPopup;
    },
    close3rdPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.tbmData.tbmRiskList.splice(0, 0, {
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(),
            tbmRiskProcess: _item.processName,
            tbmRisk: _item.riskHazardQuestionName,
            tbmImprove: _item.improvementMeasures,
            tbmRiskKeyFlag: 'N',
            tbmRiskType: '3단계판단법',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    close4MPopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tbmData.tbmRiskList.splice(0, 0, {
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobName, // 작업단계가 없기 때문에 작업으로 <<
            tbmRisk: item.riskHazardName,
            tbmImprove: item.addingRiskManagementActivities, // 안전대책은 추가 리스트관리 계획
            tbmRiskKeyFlag: 'N',
            tbmRiskType: '4M',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    closeJRAPopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tbmData.tbmRiskList.splice(0, 0, {
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobStepName, 
            tbmRisk: item.riskHazardName,
            tbmImprove: item.currentSafetyMeasures, // 안전대책은 현재안전조치
            tbmRiskKeyFlag: 'N',
            tbmRiskType: 'JRA',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    // TBM 참석자 협력사에서 선택
    addAttendeePartner() {
      this.popupOptions.title = "업체 근무자 검색"; 
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
        vendorCd: this.tbmData.vendorCd // 수급업체코드
      };
      this.popupOptions.target = () => import(`${"@/pages/common/vendor/vendorUserPop.vue"}`);
      this.popupOptions.width = "100%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePartnerPopup;
    },
    closePartnerPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
            tbmAttendeeUserId: _item.userId});
          if (index === -1) {
            this.tbmData.tbmAttendeeList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeDept: '(업체) ' + _item.vendorName,  // 소속명
              vendorCd: _item.vendorCd,
              tbmAttendeeUserId: "",
              tbmAttendeeName: _item.personName,
              tbmAttendeeJob: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFlag: "N",
              phychologicalFlag: "N",
              physicalFlag: "N",
              physicalDefectFlag: "N",
              attendeeType: '파트너',
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee() {
      this.popupOptions.title = "TBM 참석자 검색"
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendee;
    },
    closeAttendee(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tbmData.tbmAttendeeList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeDept: '(현업) ' + _item.deptName,  // 소속명
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeName: _item.userName,
              tbmAttendeeJob: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFlag: "N",
              phychologicalFlag: "N",
              physicalFlag: "N",
              physicalDefectFlag: "N",
              attendeeType: '내부',
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendeeDirect() {
      // 참석자 직접추가
      let newAttendee = {
        tbmId: this.popupParam.tbmId,
        tbmAttendeeId: uid(),
        tbmAttendeeUserId: "",
        tbmAttendeeUserName: "",
        tbmAttendeeJob: "",
        tbmAttendeeDept: '',  
        healthStatusCd: "THS0000001",
        mentalFlag: "N",
        phychologicalFlag: "N",
        physicalFlag: "N",
        physicalDefectFlag: "N",
        attendeeType: '직접추가',
        remark: "",
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      }
      this.tbmData.tbmAttendeeList.push(newAttendee);
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", 
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmAttendeeList) 
            {this.tbmData.deleteTbmAttendeeList = [];}
          if (this.$_.findIndex(this.tbmData.deleteTbmAttendeeList, {
              tbmAttendeeId: item.tbmAttendeeId}) === -1 && item.editFlag !== "C") 
            {this.tbmData.deleteTbmAttendeeList.push(item);}
          this.tbmData.tbmAttendeeList = this.$_.reject(this.tbmData.tbmAttendeeList, item);
        });
      }
    },
    copyTbm() {
      this.popupOptions.title = "타 TBM 검색"
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./tbmPop.vue"}`);
      this.popupOptions.width = '100%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTbmPopup;
    },
    closeCopyTbmPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tbmData.tbmRiskList = [];
      if (data && data.length > 0 && data[0].tbmId) {
        this.$http.url = this.$format(this.getUrl, data[0].tbmId);
        this.$http.type = "GET";
        this.$http.request((_result) => {
          this.tbmData = _result.data
          this.tbmData.permitDate = _result.data.tbmDate
          this.tbmData.tbmDate = this.$comm.getToday();
          this.workTime = [_result.data.tbmStartTime, _result.data.tbmEndTime]
          this.tbmData.tbmCompleteFlag = "N";
        });
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    copySwp(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
      this.$http.url = this.$format(this.getWorkPermitUrl, data[0].sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tbmData.permitNo = _result.data.permitNo + " | " + _result.data.permitDate
        this.tbmData.sopWorkPermitId = _result.data.sopWorkPermitId // 허가번호
        this.tbmData.tbmDate = _result.data.permitDate // 작업일
        this.tbmData.permitDate = _result.data.permitDate
        this.tbmData.processCd = _result.data.processCd // 공정
        this.tbmData.vendorCd = _result.data.vendorCd // 업체
        this.tbmData.tbmName = _result.data.sopName // 작업
        this.tbmData.tbmLocation = _result.data.workLocation // 작업장소
        this.tbmData.tbmName = _result.data.sopName // TBM명
        this.tbmData.tbmContents = _result.data.workSummary // 작업내용
        // 들고온 작업허가서에 책임자가 없으면 디폴트값으로 작성자 id 자동입력
        if( _result.data.workManagerId) {this.tbmData.tbmLeaderId = _result.data.workManagerId}
        else this.tbmData.tbmLeaderId = this.$store.getters.user.userId

        this.tbmData.tbmRiskList = []
        this.tbmData.deleteTbmRiskList = []
        if (_result.data.assessments && _result.data.assessments.length > 0) {
          this.$_.forEach(_result.data.assessments, _item => {
            this.tbmData.tbmRiskList.push({
              tbmId: this.tbmData.tbmId,
              tbmRiskId: uid(),
              tbmRiskProcess: _item.jobStepName,
              tbmRisk: _item.riskHazardName,
              tbmImprove: _item.safetyActionMeasures,
              tbmRiskKeyFlag: "N",
              tbmRiskType: '작업허가서',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          })
        } 
        this.tbmData.tbmAttendeeList = [];
        this.tbmData.deleteTbmAttendeeList = [];
        if (_result.data.workers && _result.data.workers.length > 0) {
          this.$_.forEach(_result.data.workers, _item => {
            let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {tbmAttendeeName: _item.workerName,});
            if (index === -1) {
              let attendType = '직접추가'
              if (_item.belongName.includes('현업')) {attendType = '내부';
              } else if (_item.belongName.includes('협력업체')) {attendType = '파트너';}
              this.tbmData.tbmAttendeeList.push({
                tbmId: this.tbmData.tbmId,
                tbmAttendeeId: uid(),
                tbmAttendeeUserId: _item.userId,
                tbmAttendeeName: _item.workerName,
                tbmAttendeeDept: _item.belongName,
                tbmAttendeeJob: _item.jobName,
                healthStatusCd: "THS0000001",
                mentalFlag: "N",
                phychologicalFlag: "N",
                physicalFlag: "N",
                physicalDefectFlag: "N",
                attendeeType: attendType,
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            }
          })
        } 
        
      },);
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: 'TBM을 삭제하시겠습니까? \r\n삭제할 시 모든 정보가 삭제됩니다.',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tbmData.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // signCallback(data) { 
    //   this.tbmData.tbmLeaderSign = data
    //   this.$refs["sign_pop"].hide();
    // },
    attendeeSignCallback(data, rowIndex) {
      this.tbmData.tbmAttendeeList[rowIndex].attendeeSign = data
      if(this.tbmData.tbmAttendeeList[rowIndex].editFlag !== 'C') {
        this.tbmData.tbmAttendeeList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
    tableDataChange(prop, col){
      if (col.name === 'tbmRiskKeyFlag'){
        this.tbmData.tbmRiskList.forEach((item, index)=>{
          // if (index === checkIndex){this.$set(item, 'tbmRiskKeyFlag', 'Y')}
          if (index !== prop.rowIndex){this.$set(item, 'tbmRiskKeyFlag', 'N')}
        })
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    closeAttendeeDetail(attendeeData, rowIndex){
      this.$set(this.tbmData.tbmAttendeeList, rowIndex, attendeeData);
      let refName = 'detail_' + rowIndex;
      this.$refs[refName].hide();
    },
    input(data){
      if (data && data.length > 0) {
        this.$http.url = this.$format(this.getWorkPermitUrl, data);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData.permitNo = _result.data.permitNo + " | " + _result.data.permitDate
          this.tbmData.sopWorkPermitId = _result.data.sopWorkPermitId // 허가번호
          this.tbmData.tbmDate = _result.data.permitDate // 작업일
          this.tbmData.permitDate = _result.data.permitDate
          this.tbmData.processCd = _result.data.processCd // 공정
          this.tbmData.vendorCd = _result.data.vendorCd // 업체
          this.tbmData.tbmName = _result.data.sopName // 작업
          this.tbmData.tbmLocation = _result.data.workLocation // 작업장소
          this.tbmData.tbmName = _result.data.sopName // TBM명
          this.tbmData.tbmContents = _result.data.workSummary // 작업내용
          // 들고온 작업허가서에 책임자가 없으면 디폴트값으로 작성자 id 자동입력
          this.tbmData.tbmLeaderId = _result.data.workManagerId || this.$store.getters.user.userId

          this.$_.forEach(this.tbmData.tbmRiskList, (item) => {
            if (!this.tbmData.deleteTbmRiskList) {this.tbmData.deleteTbmRiskList = [];}
            if (this.$_.findIndex(this.tbmData.deleteTbmRiskList, {tbmRiskId: item.tbmRiskId,}) === -1 && item.editFlag !== "C"
            ) {this.tbmData.deleteTbmRiskList.push(item);}
            this.tbmData.tbmRiskList = this.$_.reject(this.tbmData.tbmRiskList, item);
          });
          this.tbmData.tbmRiskList = [];
          if (_result.data.workers && _result.data.workers.length > 0) {
            this.$_.forEach(_result.data.workers, _item => {
              let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
                tbmAttendeeName: _item.workerName, 
              });
              if (index === -1) {
                let attendType = '직접추가'
                if (_item.belongName.includes('현업')) {
                  attendType = '내부';
                } else if (_item.belongName.includes('협력업체')) {
                  attendType = '파트너';
                }
                this.tbmData.tbmAttendeeList.push({
                  tbmId: this.tbmData.tbmId,
                  tbmAttendeeId: uid(),
                  tbmAttendeeUserId: _item.userId,
                  tbmAttendeeName: _item.workerName,
                  tbmAttendeeDept: _item.belongName,
                  tbmAttendeeJob: _item.jobName,
                  healthStatusCd: "THS0000001",
                  mentalFlag: "N",
                  phychologicalFlag: "N",
                  physicalFlag: "N",
                  physicalDefectFlag: "N",
                  attendeeType: attendType,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: "C",
                })
              }
            })
          } 
          if (_result.data.assessments && _result.data.assessments.length > 0) {
            this.$_.forEach(_result.data.assessments, _item => {
              this.tbmData.tbmRiskList.push({
                tbmId: this.tbmData.tbmId,
                tbmRiskId: uid(),
                tbmRiskProcess: _item.jobStepName,
                tbmRisk: _item.riskHazardName,
                tbmImprove: _item.safetyActionMeasures,
                tbmRiskKeyFlag: "N",
                tbmRiskType: '작업허가서',
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            })
          } 
        },);
      }
    },
    removeWorkPermit() {
      this.init()
    },

  },
};
</script>